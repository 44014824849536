// Theme fonts

$arial-black: 'Arial W01 Black', 'Arial Black', 'Arial Bold', arial, sans-serif;
$arial-bold: 'Arial W01 Bold903304', 'Arial Bold', arial, sans-serif;
$arial-narrow: 'Arial W02 Regular903376', arial, sans-serif;
$arial-narrow-bold: 'Arial W01 Medium', arial, sans-serif;

$arial-narrow-italic: 'Arial W01 Italic903295', arial, italic;
$arial-narrow-bold-italic: 'Arial W01 Medium Italic', arial, italic;
$arial-bold-italic: 'Arial W01 Bold Italic903307', 'Arial Bold', arial, sans-serif;
