// Theme colors

$denim: #1867C9;
$cerulean: #00beff;
$sunglow: #ffc72c;
$goldenrod: #ffdf2c;
$white: #fff;
$black: #000;
$crimson: #da291c;
$orange: #ff671f;
$viking: #7db3df;
$dark-grey: #75787b;


// List color
$pale-blue: #e5f8ff;


//colored boxes
$green-box: #85be00;
$grey-box: #a3b2a4;
$grey-box-light: #e3e8e4;
$purple-box: #c800a1;

// CCA Colors
$cca-dark-blue: #1867C9;
$cca-grey: #687777;
