@charset "UTF-8";

@import 'imports';
@import 'blog';
@import 'print';

// BEGIN General Styles

body {
  padding-bottom: 5px;
  padding-top: 0;
  background-color: #FFFFFF;
  letter-spacing: 0;
}

*::selection {
  background-color: $cerulean;
}

#Wrapper {
  width: 1200px;

  @media screen and (max-width:1239px) {
    width: 960px;
  }

  @media screen and (max-width:959px) {
    width: 728px;
  }

  @media screen and (max-width:767px) {
    width: 420px;
  }

  @media screen and (max-width:479px) {
    width: 300px;
  }
}

p {
  color: $black;
  font-family: $arial-narrow;
  font-size: 18px;
  line-height: 26px;
  margin: 0 0 20px;

  @media screen and (max-width:767px) {
    font-size: 16px;
    line-height: 22px;
  }
}

h1 {
  color: $cerulean;
  font-size: 52px;
  line-height: 52px;
  margin-bottom: 28px;

  @media screen and (max-width: 767px) {
    font-size: 43px;
    line-height: 43px;
  }
}

h4 {
  color: $black;
  font-family: $arial-narrow-bold;
  font-size: 20px;
  line-height: 24px;
}

h3 {
  font-family: $arial-narrow;
  font-size: 28px;
  line-height: 30px;
}

h5 {
  color: $cerulean;
  font-family: $arial-narrow-bold;
  font-size: 24px;
  line-height: 32px;
}

h6 {
  letter-spacing: 0;
}

i,
em {
  font-family: $arial-narrow-bold-italic;
}

p b,
p strong,
b,
strong {
  font-family: $arial-bold;
  font-weight: normal;
}

a {
  color: $orange;
  font-family: $arial-narrow-bold;
  transition: color .3s;

  &:hover {
    color: $black;
    text-decoration: none;
  }
}

.hr_color {
  background: $cca-grey;
  color: $cca-grey;
  height: 2px;
}

.column,
.columns {
  margin: 0 1% 30px;
}

ul {
  list-style: none !important;

  li {
    list-style: none !important;

    &:before {
      color: $cerulean;
      content: '•';
      font-family: 'webdings';
      margin: 0 9px 0 -19px;
      vertical-align: text-bottom;

      @media screen and (max-width:767px) {
        margin: 0 9px 0 -14px;
      }

      // @media screen and (max-width:767px) {
      //   margin: 0 5px 0 -19px;
      // }
    }
  }
}

.menu-item:before,
.board ul li:before,
.team ul li:before,
.widget ul li:before,
.blue-white ul li:before {
  content: '';
  display: none;
  margin: 0;
}

// Quote Styling
blockquote {
  background: none;
  font-family: $arial-bold-italic !important;
  font-size: 28px;
  line-height: 32px;

  @media screen and (max-width:767px) {
    font-size: 22px;
    line-height: 26px;
  }

  &:after {
    background: none;
  }

  // &:after {
  //   background: url('/wp-content/themes/betheme-child/images/blue-quote.png');
  //   content: '';
  //   height: 35px;
  //   left: -45px;
  //   position: absolute;
  //   top: -13px;
  //   width: 40px;
  // }
}

.quote-name {
  display: block;
  float: left;
  font-family: $arial-bold !important;
  font-size: 18px;
  margin-bottom: 7px !important;
  margin-left: 60% !important;
  text-align: left;
  text-transform: uppercase;

  @media screen and (max-width:767px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0 !important;
    margin-left: 50% !important;
    position: initial;
  }
}

.quote-title {
  display: block;
  float: left;
  font-family: $arial-narrow !important;
  font-size: 16px;
  line-height: 0;
  margin-left: 60% !important;
  text-align: left;

  @media screen and (max-width:767px) {
    font-size: 16px;
    line-height: 20px;
    margin-left: 50% !important;
    margin-bottom: 0;
    position: initial;
  }
}

.spotlight .quote-title {

  &:nth-of-type(3),
  &:nth-of-type(4),
  &:nth-of-type(5) {
    margin-top: -29px;

    @media screen and (max-width:959px) {
      margin-top: 20px;
    }

    @media screen and (max-width:767px) {
      margin-top: 0;
    }
  }
}

.header-quote {
  padding-bottom: 60px;

  @media screen and (max-width:767px) {
    padding-bottom: 30px;
  }

  blockquote {
    color: $white;
    font-family: $arial-narrow-bold-italic;
    font-size: 22px;
    line-height: 30px;
    margin-left: 8%;
    margin-right: 8%;

    @media screen and (max-width: 1239px) {
      margin-left: 11%;
      margin-right: 11%;
    }

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }

    // &:after {
    //   background: url('/wp-content/themes/betheme-child/images/white-quote.png');
    //   left: -20px;
    //   top: -18px;

    //   @media screen and (max-width: 1239px) {
    //     left: -48px;
    //   }

    //   @media screen and (max-width: 959px) {
    //     left: -43px;
    //   }

    //   @media screen and (max-width:767px) {
    //     left: -35px;
    //   }

    //   @media screen and (max-width:479px) {
    //     left: -20px;
    //     top: -35px;
    //   }
    // }
  }
}

.blue-quote,
.white-quote,
.yellow-quote,
.grey-quote {
  blockquote {
    font-family: $arial-narrow-bold-italic;
    font-size: 22px;
    line-height: 32px;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 25px;

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }

    // &:after {
    //   left: -45px;
    //   top: 7px;

    //   @media screen and (max-width:767px) {
    //     left: -35px;
    //   }

    //   @media screen and (max-width:479px) {
    //     left: -15px;
    //     top: -10px;
    //   }
    // }
  }

  .quote-name {
    color: $cerulean;
  }
}

.blue-quote {
  blockquote {
    color: $cerulean;

    // &:after {
    //   background: url('/wp-content/themes/betheme-child/images/blue-quote.png');
    // }
  }

  .quote-name {
    color: $cerulean;
  }
}

.white-quote {
  blockquote {
    color: $white;

    // &:after {
    //   background: url('/wp-content/themes/betheme-child/images/white-quote.png');
    // }
  }

  .quote-name {
    color: $white;
  }
}

.yellow-quote {
  blockquote {
    color: $denim;

    // &:after {
    //   background: url('/wp-content/themes/betheme-child/images/white-quote.png');
    // }
  }

  .quote-name {
    color: $white;
  }
}

.grey-quote {
  blockquote {
    color: $dark-grey;

    // &:after {
    //   background: url('/wp-content/themes/betheme-child/images/blue-quote.png');
    // }
  }

  .quote-name {
    color: $black;
  }

  .quote-title {
    color: $dark-grey;
  }

}

.image_frame .image_wrapper .image_links {
  background: $cerulean;
  display: none;

  a:hover {
    color: $cerulean;
  }
}

.header-image {
  background-size: cover !important;
}

.landing-header {
  height: 445px;
  margin: 0 1%;

  @media screen and (max-width:767px) {
    height: 425px;
  }

  h2 {
    color: $goldenrod;
    font-family: $arial-bold;
    font-size: 60px;
    line-height: 62px;
    padding: 12% 14%;
    text-shadow: 1px 1px 20px $black;

    @media screen and (max-width:1239px) {
      padding: 14% 13%;
    }

    @media screen and (max-width:959px) {
      padding: 17% 2%;
    }

    @media screen and (max-width:767px) {
      font-size: 42px;
      line-height: 44px;
      padding: 22% 0 0;
    }
  }
}

.landing-subheader {
  background-size: cover;
  padding: 25px;

  h3 {
    color: $white;
    font-size: 44px;
    line-height: 46px;
    margin-top: 50px;

    @media screen and (max-width:767px) {
      font-size: 36px;
      line-height: 40px;
      margin-top: 28px;
    }
  }

  h6 {
    color: $white;
    font-family: $arial-narrow-bold;
    font-size: 20px;
    line-height: 28px;
    margin: 0 10% 40px;

    @media screen and (max-width:767px) {
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 30px;
    }
  }

  p {
    color: $white;
    font-family: $arial-narrow-bold;
    margin: 0 20px;

    @media screen and (max-width:767px) {
      margin: 0;
    }
  }
}

.section-heading {
  color: $cerulean;
  font-size: 44px;
  line-height: 46px;
  margin-top: 50px;

  @media screen and (max-width:767px) {
    font-size: 36px;
    line-height: 40px;
    margin-top: 30px;
  }
}

.section-subhead {
  font-family: $arial-narrow;
  font-size: 20px;
  line-height: 28px;
  margin: 0 9%;

  @media screen and (max-width:767px) {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 15px;
  }
}

.caps {
  text-transform: uppercase;
}

.colored-box.quote-box {
  margin: 0 12px;

  @media screen and (max-width:1239px) {
    margin: 0 20px;
  }

  @media screen and (max-width:959px) {
    margin: 0 18px;
  }

  @media screen and (max-width:767px) {
    margin: 0;
  }
}

.colored-box .mcb-wrap {
  padding: 0 3.5%;

  @media screen and (max-width:959px) {
    padding: .5%;
  }
}

.column_column ul,
.column_column ol,
.column_visual ul,
.column_visual ol,
.mfn-acc ul,
.mfn-acc ol,
.ui-tabs-panel ul,
.ui-tabs-panel ol,
.the_content_wrapper ul,
.the_content_wrapper ol {
  color: $black;
  font-family: $arial-narrow;
  font-size: 18px;
  line-height: 26px;

  @media screen and (max-width:767px) {
    font-size: 16px;
    line-height: 22px;
  }
}

a.mfn-link {
  white-space: normal;
}

// Fancy Link Styling
a.mfn-link-4 {
  color: $cerulean;
  font-family: $arial-black;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 15px 0;
  text-transform: uppercase;

  &.white-link {
    color: $white;
  }

  &:before,
  &:after {
    background: rgba(0, 0, 0, .1);
    transform: none;
  }

  &:before {
    margin-top: -3px;
  }
}

a:hover.mfn-link-4:before,
a.hover.mfn-link-4:before {
  background: $cerulean;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

a:hover.mfn-link-4:after,
a.hover.mfn-link-4:after {
  background: $cerulean;
  margin-top: 0;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

a:hover.mfn-link-4.white-link:before,
a.hover.mfn-link-4.white-link:before,
a:hover.mfn-link-4.white-link:after,
a.hover.mfn-link-4.white-link:after {
  background: $white;
}

a:hover.mfn-link {
  color: $black;
}

a:hover.mfn-link.white-link {
  color: $denim;
}

// Image box Styling
.photo_box {
  .desc {
    color: $black;
    font-family: $arial-narrow-bold;
    font-size: 16px;
    line-height: 28px;
  }

  .image_frame {
    margin-bottom: 40px;
  }
}

.image_frame .image_wrapper,
.image_frame {
  border: 0;
  overflow: visible;

  .mask {
    display: none;
  }
}

// Box Button Styling
.box-button {
  a {
    font-family: $arial-black;
    font-size: 15px;
    height: 137px;
    line-height: 19px;
    margin-bottom: 0;
    text-transform: uppercase;
    width: 137px;

    @media screen and (max-width:1239px) {
      margin-top: 4px;
    }

    @media screen and (max-width:959px) {
      font-size: 14px;
      height: 118px;
      width: 118px;
    }

    &.button {
      border-color: $white;
      border-radius: 0;

      .button_label {
        float: none;
        padding: 55px 0;

        span {
          display: none;
        }

        @media screen and (max-width:959px) {
          padding: 46px 0;
        }
      }
    }

    &.blue {
      background-color: $denim !important;

      &:hover {
        color: $denim !important;
        border-color: $denim;
      }
    }

    &.green {
      background-color: $green-box !important;

      &:hover {
        color: $green-box !important;
        border-color: $green-box;
      }
    }

    &.yellow {
      background-color: $sunglow !important;

      &:hover {
        color: $sunglow !important;
        border-color: $sunglow;
      }
    }

    &.lightblue {
      background-color: $viking !important;

      &:hover {
        color: $viking !important;
        border-color: $viking;
      }
    }

    &.orange {
      background-color: $orange !important;

      &:hover {
        color: $orange !important;
        border-color: $orange;
      }
    }

    &.grey {
      background-color: $grey-box !important;

      &:hover {
        color: $grey-box !important;
        border-color: $grey-box;
      }
    }

    &.red {
      background-color: $crimson !important;

      &:hover {
        color: $crimson !important;
        border-color: $crimson;
      }
    }

    &.purple {
      background-color: $purple-box !important;

      &:hover {
        color: $purple-box !important;
        border-color: $purple-box;
      }
    }
  }
}

.purple .button_label {
  padding: 42px 0 !important;

  @media screen and (max-width:959px) {
    padding: 33px 0 !important;
  }
}

.button-stroke {

  a.button {
    border-width: 3px;
  }

  a:hover.button.button_stroke_custom {
    background-color: $white !important;
  }
}

.button-stroke a:hover.button,
.button-stroke a:hover.tp-button {
  background-color: $white !important;
}

.spotlight {

  h6 {
    color: $cerulean;
    font-family: $arial-bold;
    font-size: 28px;
    line-height: 32px;
    margin: 40px 10% 20px -4%;

    @media screen and (max-width:959px) {
      margin: 20px 0 15px 1%;
    }

    @media screen and (max-width:767px) {
      font-size: 24px;
      line-height: 28px;
      margin: 0 0 15px;
    }
  }

  p {
    margin: 0 14% 50px -4%;

    @media screen and (max-width:1239px) {
      margin: 0 0 50px -4%;
    }

    @media screen and (max-width:959px) {
      margin: 0 0 0 1%;
    }

    @media screen and (max-width:767px) {
      margin: 0;
    }
  }

  a {
    color: $orange;
    font-family: $arial-black;
    font-size: 14px;
    text-transform: uppercase;
    transition: color .3s;

    @media screen and (max-width:767px) {
      font-size: 14px;
    }

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  blockquote {
    color: $cerulean;
    margin: 0 17% 50px;

    @media screen and (max-width:959px) {
      margin: 0 0 50px 0;
    }

    @media screen and (max-width:767px) {
      margin-left: 0;
    }
  }

  .quote-name {
    color: $cerulean;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .quote-title {
    color: $black;
  }
}


// Show and hide on mobile and desktop
.desktop {

  @media screen and (max-width:767px) {
    display: none !important;
  }
}

.mobile {
  display: none !important;

  @media screen and (max-width:767px) {
    display: initial !important;
  }
}

@media screen and (max-width:767px) {
  .pad-top-30 {
    padding-top: 30px !important;
  }
}

// Hide content blocks at will
.hidden {
  display: none !important;
}

.print-only {
  display: none !important;

  @media print {
    display: block !important;
  }
}


// END General Styles


// BEGIN Main Menu Styling

#Top_bar {

  .menu>li {
    margin-left: .5% !important;
    padding-left: 0;

    @media only screen and (max-width: 767px) {
      margin-left: 0 !important;
    }

    ul {
      background-color: $cerulean;
      background-color: rgba(0, 190, 255, .92);

      @media only screen and (max-width: 767px) {
        background-color: transparent;
        box-shadow: none;
        color: $cerulean;
      }
    }

    ul li a:hover,
    ul li.hover>a {
      background: $denim !important;

      @media only screen and (max-width: 767px) {
        background: transparent !important;
      }
    }
  }

  li a {
    font-family: $arial-black !important;

    span:not(.description) {
      padding: 6px 60px 5px 10px !important;
      line-height: 14px !important;

      @media screen and (max-width:1239px) {
        padding: 6px 32px 5px 10px !important;
      }

      @media screen and (max-width:959px) {
        padding: 6px 15px 5px 10px !important;
      }
    }
  }

  .menu li ul {

    li a {
      border-bottom: 2px solid rgba(0, 0, 0, .1);
      font-size: 13px;
      padding: 4px 5px 4px 10px;

      .menu-arrow {
        color: $white;
        right: 0;
        top: 20%;
      }
    }
  }

  #logo {
    padding-bottom: 0;

    @media only screen and (max-width: 767px) {
      padding: 0 !important;
    }
  }

  .menu_wrapper {
    float: none;
    margin-left: 235px;

    @media screen and (max-width:1239px) {
      margin-left: 30px;
    }

    @media screen and (max-width:959px) {
      margin-left: 45px;
    }

    @media only screen and (max-width: 767px) {
      margin-left: 0;
    }
  }

  &:not(.is-sticky) {
    border-bottom: 0;
  }
}

.menu-highlight:not(.header-creative) #Top_bar .menu>li>a {
  -webkit-border-radius: 0;
  border-radius: 0;

  &:before {
    border-bottom: solid 6px transparent;
    border-left: solid 6px transparent;
    border-right: solid 6px $white;
    border-top: solid 6px $white;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
  }
}

.menu-highlight.header-stack #Top_bar .menu>li>a {
  font-size: 15px;
  margin: 0 0 2px;
  text-transform: uppercase;
}

#Top_bar .menu li ul li a span {
  letter-spacing: .3px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

// This made the last menu item stay Yellow at all times

// a[title~=hello] {
//   background-color: $sunglow;

//   @media only screen and (max-width: 767px) {
//     background-color: transparent;
//   }
// }

@media only screen and (min-width: 768px) {

  .menu-highlight:not(.header-simple) #Top_bar.is-sticky .menu>li>a span {
    line-height: initial !important;
  }

  .menu-highlight:not(.header-simple) #Top_bar.is-sticky .menu>li>a {
    margin: 10px 0 0 !important;
    padding: 0 !important;
  }

  #Top_bar.is-sticky {
    padding-bottom: 5px;
    padding-top: 5px;

    .menu>li {
      margin: 25px 2px 0;
    }

    #logo img {
      max-height: 50px !important;
    }

    ul.sub-menu {
      margin-top: 2px;

      ul {
        margin-top: 0;
      }
    }
  }
}


#Top_bar .menu li ul li ul {
  left: 181px;

  @media screen and (max-width:1239px) {
    left: 136px;
  }

  @media screen and (max-width:959px) {
    left: 130px;
  }

  @media screen and (max-width:820px) {
    left: 120px;
  }

  @media screen and (max-width:790px) {
    left: 120px;
  }
}

#Top_bar .menu li ul li {
  width: 181px;

  @media screen and (max-width:1239px) {
    width: 136px;
  }

  @media screen and (max-width:959px) {
    width: 130px;
  }

  @media screen and (max-width:820px) {
    width: 120px;
  }

  @media screen and (max-width:790px) {
    width: 120px;
  }
}

@media screen and (max-width:959px) {

  #Top_bar.is-sticky .container {
    width: 100%;
  }

  #Top_bar #logo img {
    margin-bottom: 15px;
  }
}

@media screen and (max-width:860px) {
  #Top_bar.is-sticky .logo {
    margin: 0 10px 0 0;
  }
}

@media screen and (max-width:790px) {
  #Top_bar.is-sticky .logo {
    margin: 0;
  }

  #Top_bar li a span:not(.description) {
    padding: 6px 13px 5px 8px !important;
  }
}

@media only screen and (max-width: 767px) {
  #Top_bar #logo img {
    margin-bottom: -5px;
    margin-top: 10px;
  }

  .logo-main {
    display: none;
  }

  #Header a.responsive-menu-toggle {
    position: absolute !important;
    top: 0 !important;
    right: -4px !important;
  }

  #Top_bar #menu ul li {

    ul li a {
      padding: 13px 20px;
    }

    a {
      padding: 15px 5px;
    }

    &:hover a {
      color: $cerulean;
    }

    ul li ul li a {
      color: $denim !important;
      padding: 13px 40px;
    }

    ul li ul li ul li a {
      color: $black !important;
      padding: 13px 60px;
    }
  }

  #Top_bar .container {
    width: 380px !important;
  }

  .menu-highlight #Top_bar #menu>ul>li.current-menu-item>a,
  .menu-highlight #Top_bar #menu>ul>li.current_page_item>a,
  .menu-highlight #Top_bar #menu>ul>li.current-menu-ancestor>a,
  .menu-highlight #Top_bar #menu>ul>li.current-page-ancestor>a,
  .menu-highlight #Top_bar #menu>ul>li.current_page_ancestor>a,
  .menu-highlight #Top_bar #menu>ul>li.hover>a {
    background: transparent;
    color: $black;
  }

  #Top_bar #menu ul li.submenu .menu-toggle {
    border-left: 1px solid rgba(0, 0, 0, .1);
  }

  #Top_bar #menu ul:first-of-type {
    margin: 15px 0;
  }

  #Top_bar a.responsive-menu-toggle i {
    font-size: 30px;
  }
}

.header-magazine #Top_bar .top_bar_right {
  display: block;
  position: absolute;
}

#Top_bar a#search_button {
  font-size: 25px;
  margin-right: 0;
  top: 20px;

  @media only screen and (max-width: 767px) {
    font-size: 25px;
    right: -8px;
    position: absolute;
    top: -13px;
  }
}

@media only screen and (max-width: 479px) {
  #Top_bar .container {
    width: 260px !important;
  }
}

#Top_bar.is-sticky .top_bar_right {
  top: 11px;
}

#Top_bar:selection {
  background-color: none;
}

#Top_bar .search_wrapper {
  background: $cerulean;
  top: 100%;
  bottom: -71px;
  left: 10px;
}

#Top_bar .column {
  margin: 0;
  width: 100%;
}

#Top_bar .search_wrapper .icon_search,
#Top_bar .search_wrapper .icon_close {
  top: 22px;
  font-size: 25px;
}

#Top_bar .search_wrapper input[type="text"] {
  padding-left: 40px;
}

::-webkit-input-placeholder {
  color: $white;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $white;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $white;
}

:-ms-input-placeholder {
  color: $white;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $cerulean inset !important;
  -webkit-text-fill-color: $black !important;
}

// END Main Menu Styling


// BEGIN Slider Styling

.homes {
  width: 100% !important;
  margin: 0;

  .section_wrapper {
    @media screen and (max-width:1239px) {
      width: 960px;
    }

    @media screen and (max-width:959px) {
      width: 768px;
    }

    @media screen and (max-width:767px) {
      width: 420px !important;
    }

    @media screen and (max-width:479px) {
      width: 300px !important;
      padding-left: 0 !important;
    }

  }
}



.section_wrapper {
  @media screen and (max-width:767px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.slider-box {
  background-color: rgba(0, 190, 255, .7);

  @media screen and (max-width:1239px) {
    padding-top: 4.5% !important;
  }

  @media screen and (max-width:959px) {
    padding-top: 1.5% !important;
    padding-bottom: 22px !important;
  }

  @media screen and (max-width:767px) {
    padding-bottom: 0 !important;
    padding-top: 10% !important;
    width: 250px !important;
  }

  @media screen and (max-width:479px) {
    padding-top: 5% !important;
    width: 264px !important;
  }

  a:hover {
    text-decoration: none;
  }
}

.slide-header {
  color: $sunglow;
  font-family: $arial-bold;
  font-size: 65px;
  line-height: 67px;
  text-shadow: 0 0 18px $cerulean;

  @media screen and (max-width:1239px) {
    font-size: 48px;
    line-height: 50px;
  }

  @media screen and (max-width:959px) {
    font-size: 36px;
    line-height: 38px;
  }

  @media screen and (max-width:479px) {
    font-size: 36px;
    line-height: 38px;
  }
}

.slide-copy {
  color: $white;
  font-family: $arial-narrow-bold;
  font-size: 20px;
  line-height: 24px;
  text-shadow: 0 0 18px $cerulean;

  @media screen and (max-width:1239px) {
    font-size: 17px;
    line-height: 20px;
  }

  @media screen and (max-width:959px) {
    font-size: 16px;
    line-height: 18px;
  }

  @media screen and (max-width:767px) {
    font-size: 16px;
    line-height: 22px;
  }

  &:after {
    content: url('/wp-content/themes/betheme-child/images/yellow-arrow.png');
    padding-left: 2px;
    position: absolute;
  }
}

// END Slider Styling


// BEGIN Homepage Styles

#fifteen {

  .infobox {
    background-color: transparent;
    background-position: left top;
    height: 270px;
    padding: 0;

    @media screen and (max-width:767px) {
      background-image: none !important;
      background-color: $denim;
      height: inherit;
      text-align: center;
    }

    h2 {
      color: $white;
      font-family: $arial-black;
      font-size: 38px;
      line-height: 34px;
      font-weight: 300;
      letter-spacing: 0px;

      @media screen and (max-width:767px) {
        font-size: 30px;
      }

      @media screen and (max-width:479px) {
        font-size: 28px;
        line-height: 30px;
      }
    }

    p {
      color: $white;
      font-family: $arial-narrow-bold;
      font-size: 22px;
      line-height: 30px;
      margin-left: 60px;
      margin-right: 70px;

      @media screen and (max-width:1239px) {
        line-height: 28px;
        margin-left: 55px;
        margin-right: 31px;
      }

      @media screen and (max-width:959px) {
        font-size: 18px;
        margin-left: -327px;
        margin-right: 63px;
      }

      @media screen and (max-width:767px) {
        font-size: 16px;
        line-height: 22px;
        margin: 0;
      }
    }
  }

  .infobox_wrapper {
    padding: 100px 20px 0 344px;

    @media screen and (max-width:1239px) {
      padding: 80px 10px 0 374px;
    }

    @media screen and (max-width:959px) {
      padding: 64px 10px 0 393px;
    }

    @media screen and (max-width:767px) {
      padding: 25px;

      &:before {
        content: url('/wp-content/themes/betheme-child/images/15.png');
        margin-left: -36px;
      }
    }
  }
}

#results {
  text-align: center;

  @media screen and (max-width:767px) {
    padding-top: 50px !important;
  }

  h3 {
    color: $cerulean;
    font-family: $arial-narrow-bold;
    font-size: 55px;
    line-height: 55px;
    text-transform: uppercase;

    @media screen and (max-width:959px) {
      margin: 0 34px 20px;
    }

    @media screen and (max-width:767px) {
      font-size: 40px;
      line-height: 45px;
      margin: 0 43px 15px;
    }

    @media screen and (max-width:479px) {
      margin: 0 0 15px;
    }

    .results {
      color: $orange;
      font-family: $arial-black;
      margin-bottom: 34px !important;
    }
  }

  p {
    color: $black;
    margin: 0 290px;

    @media screen and (max-width:1239px) {
      margin: 0 160px;
    }

    @media screen and (max-width:959px) {
      margin: 0 100px;
    }

    @media screen and (max-width:767px) {
      margin: 0 20px;
    }

    @media screen and (max-width:479px) {
      margin: 0;
    }
  }
}

.recent-outcomes {
  margin: 10px;

  @media screen and (max-width:767px) {
    margin: 6px 0 !important;
  }

  h4 {
    color: $white;
    font-family: $arial-narrow-bold;
    font-size: 24px;
    margin-bottom: 0;

    @media screen and (max-width:767px) {
      font-size: 18px;
    }
  }
}

.recent-news {
  margin-top: 50px;
}

.icon_wrapper {
  display: none;
}

.counter-title {
  margin-bottom: 23px;
  margin-top: 43px;
  text-align: center;

  @media screen and (max-width:959px) {
    margin-bottom: 15px;
  }

  @media screen and (max-width:767px) {
    margin-bottom: 0 !important;
  }

  h4 {
    color: $denim;
    font-family: $arial-black;
    font-size: 36px;
    line-height: 32px;

    @media screen and (max-width:959px) {
      font-size: 30px;
      line-height: 26px;
    }

    @media screen and (max-width:767px) {
      font-size: 28px;
      line-height: 15px;
    }
  }

  h5 {
    color: $denim;
    font-family: $arial-narrow;
    font-size: 24px;
    line-height: 20px;

    @media screen and (max-width:959px) {
      font-size: 20px;
    }

    @media screen and (max-width:767px) {
      font-size: 22px;
    }
  }
}

.number-wrapper {

  span {
    color: $cerulean;
    font-family: $arial-black;
    font-size: 90px;

    @media screen and (max-width:1239px) {
      font-size: 80px;
    }

    @media screen and (max-width:959px) {
      font-size: 70px;
    }

    &.label {
      font-family: $arial-bold;
      font-size: 75px;

      @media screen and (max-width:1239px) {
        font-size: 65px;
      }

      @media screen and (max-width:959px) {
        font-size: 60px;
      }
    }
  }
}

.counter {
  background-image: none;
  padding: 0;

  .desc_wrapper .number-wrapper {
    margin-bottom: 10px;
  }

  .desc_wrapper .title {
    color: $black;
    font-family: $arial-narrow-bold;
    font-size: 18px;
    line-height: 24px;
    margin: 0 30px;

    @media screen and (max-width:1239px) {
      margin: 0 10px;
    }

    @media screen and (max-width:959px) {
      font-size: 16px;
      line-height: 24px;
      margin: 0 2px;
    }

    @media screen and (max-width:767px) {
      margin: 0 0 15px;
    }
  }
}

.blue-divider hr {
  background-color: $viking;
  color: $viking;
  height: 2px;
  width: 97%;

  @media screen and (max-width:959px) {
    width: 100%;
  }

  @media screen and (max-width:767px) {
    margin-bottom: 0;
  }
}

.home-spotlight .spotlight {

  h6,
  blockquote,
  .quote-name {
    color: $white;
  }

  blockquote {
    text-align: center;
  }

  a {
    color: $sunglow;

    &:hover {
      color: $black;
    }
  }
}

.our-boxes {

  .column:first-child {
    margin-right: 0;
    width: 49%;

    @media screen and (max-width:767px) {
      margin-bottom: 0;
    }
  }

  .column:last-child {
    margin-left: 0;
    width: 49%;
  }
}

// END Homepage Styles


// BEGIN About Page Styling

.equation-list {

  ul {
    margin-top: 20px;

    @media screen and (max-width:767px) {
      margin-left: 0;
    }
  }

  li {
    color: $black;
    font-family: $arial-narrow-bold;
    font-size: 18px;
    line-height: 32px;
    list-style: none !important;
    text-indent: -.9em;
    text-transform: uppercase;

    @media screen and (max-width:1239px) {
      line-height: 30px;
    }

    @media screen and (max-width:959px) {
      font-size: 17px;
      line-height: 20px;
      text-indent: -18px;
    }

    @media screen and (max-width:767px) {
      font-size: 16px;
      text-indent: 0;
    }

    &:before {
      content: url('/wp-content/themes/betheme-child/images/dot-orange.png');
      padding-right: 10px;
      vertical-align: middle;
      margin: 0;
    }
  }
}

.small-column p {
  font-size: 16px;
  line-height: 24px;

  .caps {
    color: $white;
    font-family: $arial-black;

    @media screen and (max-width:959px) {
      font-family: $arial-narrow-bold;
      font-size: 18px;
    }

    @media screen and (max-width:767px) {
      font-family: $arial-bold;
      font-size: 16px;
    }
  }
}


// END About Page Styling


// BEGIN Our People page styling

.team {
  margin-top: 20px;

  @media screen and (max-width:767px) {
    margin-bottom: 0 !important;
  }

  ul li {
    color: $white;
    font-family: $arial-narrow;
    font-size: 15px;
    line-height: 20px;
    list-style: none;
    margin-bottom: 24px;
  }

  .team-member,
  .team-member a {
    color: $sunglow;
    font-family: $arial-black;
    font-size: 17px;

    a:hover {
      color: $black;
    }
  }
}

.box-two {
  margin-top: 3px;
}

.board {

  @media screen and (max-width:767px) {
    margin-bottom: 0 !important;
  }

  ul {
    margin-left: 0;

    li {
      color: $black;
      font-family: $arial-narrow;
      font-size: 15px;
      line-height: 22px;
      list-style: none;
      margin-bottom: 24px;

      h6 {
        color: $cerulean;
        font-family: $arial-bold;
        font-size: 17px;
        line-height: 24px;
        text-transform: uppercase;
      }
    }
  }

  .team-member {
    color: $orange;
    font-family: $arial-black;
    font-size: 18px;
  }

  strong {
    font-family: $arial-narrow-bold;
  }
}

// END Our People page styling


// BEGIN Careers Page Styling

.long-text h2 {
  padding: 7% 14%;

  @media screen and (max-width:1239px) {
    padding: 7% 9%;
  }

  @media screen and (max-width:959px) {
    font-size: 54px;
    padding: 9% 1%;
  }

  @media screen and (max-width:767px) {
    font-size: 42px;
    padding: 9% 1% 0;
  }
}

// Apply and Find Your Fit Page Styling

.apply-boxes {

  .spacer {
    min-height: 153px;

    @media screen and (max-width:767px) {
      display: none;
    }
  }

  .box-button a.button .button_label {
    line-height: 15px;
    padding: 58px 0;

    @media screen and (max-width:959px) {
      padding: 44px 0;
    }
  }
}

.not-open {
  font-family: $arial-narrow-bold;
  font-size: 14px;
  line-height: 10px;
  text-transform: none;

  &.blue {
    color: $denim;
  }

  &.green {
    color: $green-box;
  }

  &.yellow {
    color: $sunglow;
  }

  &.grey {
    color: $grey-box;
  }

  &.orange {
    color: $orange;
  }

  &.red {
    color: $crimson;
  }

  &.purple {
    color: $purple-box;
  }
}

.box-button.fit a {
  font-family: $arial-narrow-bold;
  font-size: 16px;
  height: 137px;
  width: 137px;
}

.three .button_label {
  padding: 42px 0 !important;
}

.box-button.role a.grey:hover {
  color: $white !important;
  border-color: $white;
  background-color: $cerulean !important;
}

.fit h6 {
  color: $cerulean;
  font-family: $arial-bold;
  font-size: 18px;
  text-transform: uppercase;
}

.fit a.button {
  margin-right: 0;
}

.apply-container {
  left: 0;
  position: fixed;
  top: 50%;
  z-index: 100;
}

#apply {
  transform: rotate(-90deg);
  transform-origin: left top 0;

  a {
    background-color: $cerulean;
    -moz-box-shadow: 0 0 8px $grey-box;
    -webkit-box-shadow: 0 0 8px $grey-box;
    box-shadow: 0 0 8px $grey-box;
    color: $white;
    font-family: $arial-bold;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 15px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;

    &:hover {
      background-color: $denim;
      -moz-box-shadow: inset 0 0 10px $dark-grey;
      -webkit-box-shadow: inset 0 0 10px $dark-grey;
      box-shadow: inset 0 0 10px $dark-grey;
    }
  }
}

// END Careers Page Styling


// BEGIN Second Level Subpage Styling

.one.column.header-divider {
  margin: 0 0 40px;
  width: 100%;

  hr {
    background-color: rgba(0, 0, 0, .2);
    color: rgba(0, 0, 0, .2);
  }
}

.alignright {
  margin: 0 0 15px 15px;
}

.article_box {
  float: right;
  text-align: center;
  width: 50%;

  .desc_wrapper {
    background: none;
    float: right;
    min-height: initial;
    padding: 10px 0 0;
    width: 100%;

    h4 {
      border-bottom: 2px solid $grey-box;
      border-top: 2px solid $grey-box;
      color: $orange;
      font-family: $arial-narrow-bold;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 0;
      margin-left: 30px;
      margin-right: 5px;
      padding: 15px 45px;

      @media screen and (max-width: 1239px) {
        padding: 15px 30px;
      }

      @media screen and (max-width: 959px) {
        margin-left: 15px;
        padding: 15px;
      }

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 26px;
        margin-left: 15px;
        padding: 10px 5px;
      }
    }

    i.icon-right-open {
      display: none;
    }
  }

  .photo_wrapper {
    display: none;
  }
}

.no-hover-box .image_frame .image_wrapper .image_links,
.no-hover-box .image_frame .image_wrapper .mask,
.no-hover-box .image_frame .mask {
  display: none;
}

.page-rule {
  width: 100%;

  hr {
    margin: 20px 0 30px !important;

    @media screen and (max-width: 767px) {
      margin: 10px 0 20px !important;
    }
  }
}

.wp-caption-text {
  background: $cerulean;
  color: $white;
  font-family: $arial-narrow-bold;
  font-size: 16px;
  height: 67px;
  line-height: 17px;
  margin: 15px 32px;
  padding: 5% 15px;

  @media screen and (max-width: 1239px) {
    margin: 15px 10px;
  }

  @media screen and (max-width: 959px) {
    font-size: 14px;
    margin: 15px 7px;
    padding: 5% 6px;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
    height: 100%;
    margin: 15px 114px;
  }

  @media screen and (max-width: 479px) {
    margin: 15px 54px;
  }
}

.news-link {
  p {
    font-size: 16px;
    line-height: 24px;
  }

  a {
    color: $orange;
    font-family: $arial-narrow-bold;
    font-size: 20px;
    line-height: 28px;

    &:hover {
      color: $black;
    }
  }
}

.no-bottom-margin .three-fourth.column {
  margin: 0 1%;
}

.header-image.no-text {
  margin-bottom: 45px;
  min-height: 445px;

  @media screen and (max-width: 1239px) {
    min-height: 356px;
  }

  @media screen and (max-width: 959px) {
    min-height: 300px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
    min-height: 215px;
  }

  @media screen and (max-width: 479px) {
    min-height: 185px;
  }

}

.program-copy {
  ul {
    color: $black;
    font-family: $arial-narrow;
    font-size: 18px;
    line-height: 25px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }

    li {
      margin-bottom: 3px;
    }
  }
}

.accordion {
  background-color: $orange;

  .question {
    border: 0;
    border-radius: 0;
    border-width: 0;

    &.active .title {
      border-width: 0;
      color: $white;
    }

    .title>.acc-icon-plus,
    .title>.acc-icon-minus {
      color: $white !important;
      font-size: 25px;
      left: 13px;
      line-height: 25px;
      top: 60px;
    }

    .title {
      background-color: $orange;
      background-image: none;
      color: $white;
      font-family: $arial-narrow-bold;
      font-size: 30px;
      line-height: 34px;
      padding: 38px 15px 38px 20%;

      &:before {
        border: 0;
      }

      &:after {
        background: url('/wp-content/themes/betheme-child/images/white-case.png');
        content: '';
        height: 70px;
        left: 92px;
        position: absolute;
        top: 38px;
        width: 102px;

        @media screen and (max-width: 1239px) {
          left: 57px;
        }

        @media screen and (max-width: 959px) {
          display: none;
        }
      }
    }

    .answer {
      padding: 20px 10%;

      @media screen and (max-width: 767px) {
        padding: 20px 0;
      }
    }
  }
}

.projects {
  p {
    font-size: 16px;
    line-height: 24px;
  }

  h4 {
    color: $cerulean;
    font-family: $arial-bold;
    font-size: 22px;
    line-height: 28px;
    text-transform: uppercase;
  }

  a {
    color: $orange;
    font-family: $arial-narrow-bold;
    font-size: 20px;
    line-height: 26px;

    &:hover {
      color: $black;
    }
  }

  .orange-text {
    color: $orange;
  }
}

.featured .accordion,
.careers .accordion {
  background-color: transparent;

  .question {
    border: 0;
    border-radius: 0;
    border-width: 0;

    &:first-of-type .title {
      background-color: $viking;
    }

    &:nth-of-type(2) .title {
      background-color: $sunglow;
    }

    &:nth-of-type(3) .title {
      background-color: $grey-box;
    }

    &:nth-of-type(4) .title {
      background-color: $viking;
    }

    &:nth-of-type(5) .title {
      background-color: $sunglow;
    }

    &:nth-of-type(6) .title {
      background-color: $grey-box;
    }

    &:nth-of-type(7) .title {
      background-color: $viking;
    }

    &:nth-of-type(8) .title {
      background-color: $sunglow;
    }

    &.active .title {
      border-width: 0;
      color: $white;
    }

    .title>.acc-icon-plus,
    .title>.acc-icon-minus {
      color: $white !important;
      font-size: 25px;
      left: 13px;
      line-height: 25px;
      top: 20px;

      @media screen and (max-width: 767px) {
        top: 25px;
      }
    }

    .title {
      background-color: $cerulean;
      background-image: none;
      color: $white;
      font-family: $arial-narrow-bold;
      font-size: 30px;
      line-height: 25px;
      padding: 18px 15px 18px 10%;

      @media screen and (max-width: 767px) {
        font-size: 28px;
        padding: 16px 10px 16px 18%;
      }

      i {
        color: $black;
        font-family: $arial-narrow-italic;
        font-size: 18px;
        line-height: 15px;
      }

      &:before {
        border: 0;
      }

      &:after {
        background: none;
        display: none;
      }
    }

    .answer {
      padding: 20px 10%;

      @media screen and (max-width: 767px) {
        padding: 20px 0;
      }

      div {
        float: left;
        width: 20%;
        margin-right: 2%;

        @media screen and (max-width: 767px) {
          margin-right: 0;
          width: 100%;
        }
      }

      .large-quote {
        font-size: 24px;
      }

      p {
        float: left;
        width: 77%;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      strong {
        font-family: $arial-narrow-bold;
        font-weight: normal;
      }
    }
  }

  h4 {
    color: $black;
    margin-left: 10%;

    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }
}

.slider-quote {
  color: $denim;
  font-family: $arial-bold-italic;
  text-align: center;
}

.careers .accordion .question .answer p {
  width: 100%;
}

.mfn-acc ul {
  float: left;
}

// END Second Level Subpage Styling


// BEGIN Partners Pages Styling

.left-heading {
  h2 {
    color: $cerulean;
    font-family: arial, sans-serif;
    font-size: 40px;
    line-height: 40px;
  }

  h6 {
    color: $cerulean;
    font-family: $arial-bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }
}

.blue-white {
  ul {
    font-size: 18px;
    line-height: normal;
    margin: 0;

    li {
      list-style: none;
      margin-bottom: 0;
      padding: 8px 0 8px 10px;

      &:nth-of-type(odd) {
        background-color: $pale-blue;
      }

      &:nth-of-type(even) {
        background-color: $white;
      }

      a {
        color: $black;
        font-size: 18px;

        &:hover {
          color: $cerulean;
        }
      }
    }
  }

  tr {
    &:nth-of-type(odd) {
      background-color: $pale-blue;
    }

    &:nth-of-type(even) {
      background-color: $white !important;
    }
  }

  td {
    text-align: left;
    vertical-align: top;

    &:first-child {
      width: 35%;
    }
  }

  table tr:nth-child(2n) td {
    background-color: $white !important;
  }
}

// table for All Program Areas

.project-list {
  table:not(.shop_table) tr:hover td a {
    color: $orange;
  }

  tr:hover td {
    color: $black;
  }

  tr {
    &:nth-of-type(odd) {
      background-color: $grey-box-light;
    }

    &:nth-of-type(even) {
      background-color: $white !important;
    }
  }

  td {
    color: $black;
    font-family: $arial-narrow;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }

    strong {
      font-family: $arial-narrow-bold;
    }

    a {
      color: $orange;
      font-family: $arial-narrow;

      &:hover {
        color: $black !important;
      }
    }
  }
}

.yellow-info .infobox,
.blue-info .infobox {
  background-color: $sunglow;
  float: left;

  .info-image {
    float: left;
    width: 16.5%;

    @media screen and (max-width: 1239px) {
      width: 15.5%;
    }

    @media screen and (max-width: 959px) {
      width: 15%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .info-right {
    float: left;
    margin-left: 20px;
    width: 81%;

    @media screen and (max-width: 767px) {
      margin-top: 15px;
      margin-left: 0;
      width: 100%;
    }
  }

  h6 {
    color: $white;
    font-family: $arial-narrow-bold;
    font-size: 28px;
    margin-bottom: 8px;
  }

  p {
    font-family: $arial-narrow-bold;
    font-size: 18px;
    line-height: 26px;
  }

  i {
    font-family: $arial-narrow-italic;
    font-size: 16px;
  }

  .role {
    font-family: $arial-narrow;
    font-size: 18px;
    line-height: 24px;
  }
}

.blue-info .infobox {
  background-color: $viking;
}

.past-fellows {
  h5 {
    color: $cerulean;
    font-family: $arial-bold;
    font-size: 18px;
    margin-bottom: -15px;
    margin-top: 50px;
    text-transform: uppercase;
  }

  h6 {
    color: $orange;
    font-family: $arial-bold;
    font-size: 21px;
  }
}

.list-heading {
  color: $cerulean;
  font-family: $arial-bold;
  font-size: 18px;
  text-transform: uppercase;
  width: 25%;

  &:first-of-type {
    margin-left: 1%;
  }
}


// END Partners Pages Styling


// BEGIN People Pages Styling


.list-margin {
  margin-left: 30px;
}

.headshot img {
  border: 1px solid $grey-box;
  float: right;

  @media screen and (max-width:767px) {
    float: none;
  }
}

.people {
  a {
    color: $orange;
    font-family: $arial-narrow;

    &:hover {
      color: $black;
    }
  }
}


// END People Pages Styling


// BEGIN Careers Pages Styling

.our-work {
  h6 {
    color: $orange;
    font-family: $arial-narrow-bold;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  strong {
    font-family: $arial-narrow-bold;
    font-weight: normal;
    text-transform: uppercase;
  }
}

.grey-info .infobox {
  background-color: $dark-grey;
  padding: 17px 17px 1px;

  h3 {
    color: $white;
    font-family: $arial-narrow-bold;
    font-size: 26px;
    text-align: center;
  }
}

.inquiry {
  h6 {
    color: $cerulean;
    font-family: $arial-bold;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.left-field,
.right-field {
  float: left;
  width: 40%;
  margin-right: 3%;

  p {
    margin: 0;
  }

  input,
  textarea {
    border-color: $black;
    font-family: $arial-narrow;
    margin-bottom: 10px !important;

    &:focus {
      background-color: $pale-blue !important;
      border-color: $black;
      color: $black;
    }
  }

  input[type="submit"] {
    background-color: $crimson !important;
    border: 0;
    border-radius: 0;
    color: $white !important;
    float: right;
    font-family: $arial-narrow-bold;
    font-size: 16px;
    margin-right: 0;
    padding: 9px 20%;
    text-transform: uppercase;

    &:hover {
      background-color: $black !important;
    }

    @media screen and (max-width:767px) {
      width: 100%;
    }
  }

  ::-webkit-input-placeholder {
    color: $black;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: $black;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $black;
  }

  :-ms-input-placeholder {
    color: $black;
  }

  @media screen and (max-width:959px) {
    width: 47%;
    margin-right: 2.5%;
  }

  @media screen and (max-width:767px) {
    width: 100%;
    margin-right: 0;
  }
}

.wpcf7-mail-sent-ok {
  background: $green-box !important;
  font-family: $arial-narrow-bold;
  font-size: 17px;
  width: 83%;

  @media screen and (max-width:959px) {
    width: 96%;
  }

  @media screen and (max-width:767px) {
    width: 100%;
  }
}

// END Careers Pages Styling


// BEGIN Search Page Styling

.search-results .no-img .post-desc-wrapper {
  width: 80%;

  @media screen and (max-width:959px) {
    width: 100%;
  }
}

.post-links {
  float: right;
  margin-right: 50px;

  @media screen and (max-width:959px) {
    margin-right: 0
  }

  .post-more {
    font-size: 16px;

    @media screen and (max-width:767px) {
      font-size: 14px;
    }
  }
}

.post-excerpt {
  margin-bottom: -10px;

  p {
    font-size: 16px;
  }
}

.post-title h2 {
  font-size: 32px;
  line-height: 36px;

  @media screen and (max-width:767px) {
    font-size: 26px;
    line-height: 30px;
  }

  a {
    color: $cerulean;
  }

  a:hover {
    color: $black;
  }
}

.post-item {
  margin-bottom: 0;
}

.pager {
  margin-top: 15px;

  .pages {
    background-image: none;
    border-radius: 0;
    margin: 0;
    padding: 4px;

    a {
      border-radius: 0;

      @media screen and (max-width:479px) {
        line-height: 32px;
        height: 32px;
        width: 32px;
      }

      &.active,
      &:hover {
        background-color: $cerulean;
      }
    }
  }

  a.next_page,
  a.prev_page {
    display: none;
  }
}

.pager_wrapper,
.column_blog {
  margin-bottom: 0;
}


// END Search Page Styling



// BEGIN Footer Styling

#Footer .widgets_wrapper {
  padding: 0;
  text-align: center;

  .widget {
    padding: 5px 0 15px;
  }
}

.footer-share {
  color: $viking;
  font-family: $arial-black;
  font-size: 13px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

#Footer {
  background-color: $white;
  color: $black;
  margin-top: 45px;

  .footer_copy {
    background-color: $white;
    line-height: 23px;
    text-align: center;

    .container .column {
      border-top: 1px solid $black;
      padding-top: 26px;
    }

    .copyright {
      float: none;

      p {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
      }
    }

    a#back_to_top {
      float: none;
      position: absolute;
      right: 11px;

      @media screen and (max-width:1239px) {
        right: 9px;
      }

      @media screen and (max-width:959px) {
        right: 7px;
      }

      @media screen and (max-width:767px) {
        position: initial;
        right: 0;
      }
    }
  }

  a {
    color: $orange;
    font-family: $arial-narrow;
    transition: color .3s;

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  a.bottom-link {
    color: $black;
    font-family: $arial-narrow-bold;
    font-weight: normal;

    &:hover {
      color: $crimson;
    }
  }
}

.square-bullet {
  color: $orange;
  font-size: 9px;
  padding: 0 3px;
  vertical-align: super;
}

a.bottom-link {
  color: $black;
  font-weight: bold;
}


// END Footer Styling

// BEGIN Sitemap styling

.sitemap {
  a {
    font-size: 18px;
    line-height: 16px;
  }
}

// END Sitemap styling

// BEGIN AddThis styling

#Sliding-top {
  background-color: $viking;

  .share {
    text-align: right;
    float: right;
    margin-right: -535%;
    margin-top: -7.5%;
    color: $white;
    font-family: $arial-black;
    font-size: 13px;
    text-transform: uppercase;

    @media screen and (max-width:1239px) {
      margin-right: -300%;
    }

    @media screen and (max-width:959px) {
      margin-right: -100%;
    }

    @media screen and (max-width:767px) {
      margin-right: 348%;
    }
  }

  a.sliding-top-control {
    border-right-color: $viking;
  }

  .widgets_wrapper {
    padding: 0px 0 11px;

    .widget {
      float: right;
      padding: 15px 0 0;
    }
  }

  .one-second {
    margin-right: -30px;
  }

  .addthis_default_style {
    float: right;
  }
}

.stButton {
  @media screen and (max-width: 767px) {
    margin-left: 4px;
    margin-right: 4px;
  }

}

// END AddThis styling


// BEGIN IE specific fixes
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  .menu-highlight:not(.header-simple) #Top_bar.is-sticky .menu>li>a span {
    line-height: 17px !important;
  }

  .is-sticky#Top_bar ul.sub-menu {
    margin-top: 2px;
  }

  ul li:before {
    font-family: inherit;
    margin: 0 12px 0 -19px;
  }

}

// END IE specific fixes

/* PHP UPDATE STYLES */
.menu-highlight #Top_bar .menu>li.current-menu-item>a,
.menu-highlight #Top_bar .menu>li.current_page_item>a,
.menu-highlight #Top_bar .menu>li.current-menu-ancestor>a,
.menu-highlight #Top_bar .menu>li.current-page-ancestor>a,
.menu-highlight #Top_bar .menu>li.current_page_ancestor>a,
.menu-highlight #Top_bar .menu>li.hover>a {
  color: #fff;
}

.menu-highlight.header-stack #Top_bar .menu>li>a {
  margin: 0 0 2px !important;
}

#Subheader {
  display: none;
}

#Top_bar.is-sticky #menu .menu>li>a {
  margin: 0 0 2px !important;
  padding: 0px 0 !important;
}

#Top_bar.is-sticky,
.sticky-white #Top_bar.is-sticky #menu {
  background: rgba(255, 255, 255, .98) !important;
}

#Top_bar.is-sticky #menu .menu>li>a span {
  line-height: 20px !important;
}

#Top_bar.is-sticky ul.sub-menu {
  margin-top: 0;
}

.sticky-white #Top_bar.is-sticky .menu>li>a {
  color: #1969c8;
}

@media screen and (max-width: 1239px) {

  .menu-highlight #Top_bar .menu>li.current-menu-item>a,
  .menu-highlight #Top_bar .menu>li.current_page_item>a,
  .menu-highlight #Top_bar .menu>li.current-menu-ancestor>a,
	.menu-highlight #Top_bar .menu>li.current-page-ancestor>a,
  .menu-highlight #Top_bar .menu>li.current_page_ancestor>a,
  .menu-highlight #Top_bar .menu>li.hover>a {
    color: #fff !important;
  }
}

#Top_bar.is-sticky #menu .menu>li {
  margin-top: 32px;
}

#Top_bar.is-sticky #search_button {
  top: 8px;
}

.counter .desc_wrapper .number-wrapper .label {
  font-size: 75px;
}

body h3,
body h4 {
  letter-spacing: normal;
}

blockquote:after {
  content: none;
}

.one.column.header-divider {
  margin-bottom: 40px !important;
}

.layout-boxed #Wrapper {
  box-shadow: none;
}

@media only screen and (max-width: 767px) {
  #Top_bar .top_bar_right_wrapper {
    top: 35px !important;
  }

  #Top_bar .menu>li ul li a {
    color: #00beff;
  }

}

@media only screen and (max-width: 767px) {
  .header-stack #Top_bar a.responsive-menu-toggle {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 1239px) {
  #Top_bar #menu ul li a span {
    line-height: 44px !important;
  }
}

@media only screen and (max-width: 767px) {
  #Top_bar #menu ul li a span {
    line-height: 14px !important;
  }

  .menu-highlight #Top_bar .menu>li.current-menu-item>a,
  .menu-highlight #Top_bar .menu>li.current_page_item>a,
  .menu-highlight #Top_bar .menu>li.current-menu-ancestor>a,
	.menu-highlight #Top_bar .menu>li.current-page-ancestor>a,
  .menu-highlight #Top_bar .menu>li.current_page_ancestor>a,
  .menu-highlight #Top_bar .menu>li.hover>a {
    color: #1969c8 !important;
  }
}

a.button .button_icon {
  padding: 0 8px;
}

.page-id-1034 .one-sixth.column {
  clear: both;
}

#mti_wfs_colophon {
	display:none !important;
}


/* update for blog next previous posts */

.post-template-single-blog .fixed-nav {
  bottom: 120px;
}
/* fixing mobile issues with content */
@media screen and (max-width: 1024px) {
  .cca-blog .post.format-standard {
    width: 100%;
    margin: 0 0 30px 0
  }
}
@media screen and (max-width: 767px) {
  .section_wrapper {
      padding-left: 0!important;
      padding-right: 0!important;
  }
  .section_wrapper, .container, .four.columns .widget-area {
      max-width: none!important;
  }
  .the_content_wrapper ul.cca-blog {
    margin: 0;
  }
  ul.cca-blog a {
    flex-flow: column;
    img {
      width: 100%;
      height: auto;
    }
  }
  .cca-blog .post.format-standard {
    width: 100%;
    margin: 0 0 30px 0
  }
  .cca-blog h1.entry-title {
    font-size: 32px;
    line-height: 33px;
  }
}

.hide-love .post-header .title_wrapper, .post-template-single-blog .the_content_wrapper {
	margin-left:0;
	padding-left:0;
}

.page-id-7157.with_aside .sidebar.columns {
	padding-top: 110px;
}
