@import 'imports';

@media print {

  footer,
  nav,
  #Sliding-top a.sliding-top-control,
  .logo,
  #Top_bar,
  #Sliding-top,
  .mfn-link-4,
  .icon-plus,
  .icon-minus,
  .page-rule,
  .homes,
  .inquiry,
  .header-divider hr,
  .mfn-main-slider,
  #ascrail2000,
  .nicescroll-rails,
  .post-links,
  .pager,
  .post-title,
  .print-hide {
    display: none !important;
    background: transparent;
  }

  .section {
    // background-color: $white !important;
    background-image: none !important;
    background: transparent;
  }

  div {
    display: inline;
  }

  .print-only img {
    right: 130px;
    top: 10px;
    height: 75px;
    position: absolute;
    width: auto;
  }



  .one.column.header-divider {
    margin-bottom: 50px;
  }

  #Wrapper {
    width: 100%;
    margin: 0;
  }

  .one-fifth.column,
  .three-fourth,
  .one,
  .one-third,
  .two-third,
  .one-second {
    width: 80% !important;
    margin: 0 0 5px;
  }

  .one-fifth.column img {
    max-width: 300px;
    padding-bottom: 15px;
  }

  .team.one-fourth,
  .board.one-fourth {
    width: 20%;
  }

  .scale-with-grid {
    float: left;
  }

  h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 32px !important;
    line-height: 32px !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  blockquote,
  ul li:before,
  .board .team-member,
  .quote-name,
  .caps,
  .team,
  .box-two,
  .team .team-member,
  .team ul li,
  .not-open,
  a.mti_font_element {
    color: $black !important;
  }

  .landing-subheader h6,
  .section-subhead {
    margin: 0 !important;
  }

  a {
    font-family: $arial-bold;

    &:after {
      content: " (http://www.ccachicago.org" attr(href) ")";
      color: $black;
      font-size: 85%;
      word-wrap: break-word;
    }
  }

  a[href^="http://"]:after,
  a[href^="https://"]:after,
  a[href^="mailto"]:after {
    color: $black;
    content: " (" attr(href) ")";
    font-size: 85%;
    word-wrap: break-word;
  }

  .apply-container {
    position: relative;
  }

  blockquote {
    margin-left: 0;
  }

  .spotlight h6,
  .spotlight p,
  .spotlight blockquote {
    margin-left: 15px;
  }

  .spotlight .quote-name,
  .spotlight .quote-title {
    margin-left: 40% !important;
  }

  .long-text h2,
  .landing-header h2 {
    font-size: 40px;
    line-height: 42px;
    padding: 75px 0 0 0;
  }

  .header-image {
    display: none;
  }

  .one-fifth.box-button.column {
    display: inline-block;
    width: 33% !important;
  }

  .one-fifth.column.column_photo_box,
  .one-fourth.column.column_photo_box {
    width: 80% !important;

    img {
      display: none;
    }
  }

  .accordion {
    .question {
      .title {
        color: $black !important;
        padding: 0 !important;
        font-size: 22px !important;
      }

      .answer {
        display: inline-block;
        padding: 10px 0 0 15px !important;
      }
    }
  }

  .blue-white td {
    padding: 0 15px 30px 0;
  }

}
