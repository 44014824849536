
#mti_wfs_colophon {
  display: none!important;
}
.post-template-single-blog {
  #Content {
    padding-top: 0!important;
  }
}
/* Blog Article Styles */
.cca-blog {
  padding-top: 40px;

	a {
		color: $orange;
	}

	ul li:before {
		color: $black;
	}

  .column.one {
    margin-bottom: 0;
  }
  .cca-return-link {
    margin-top: 20px;
    a{
      border: none;
      color: $orange;
      font-size: 16px;
    }
  }
  .hr_color {
    margin: 30px auto!important
  }
  .with_aside .sections_group {
    width: 75%;
  }

  .post.format-standard {
		width: 73%;
		margin: 0 1% 30px 19.5%;

	}

  //Hide top post-nav
  .column.one.post-nav {
    display: none;
  }

  //Content container
  .column {
    margin-left: 0;
    margin-right: 0;
  }

  //Default featured image wrapper
  .column.one.single-photo-wrapper.image {
    display: none;
  }

  h1.entry-title {
    color: $cca-dark-blue;
      font-size: 52px;
      line-height: 52px;
      margin-bottom: 28px;
  }
h2 {
  font-size: 32px;
  font-weight: 300;
  line-height: 36px;
}
p+blockquote{
  h5 {
    text-align: left!important;
  }
  &+hr.hr_color {
    margin: 52px auto 30px!important;
  }
}
h5[style*="text-align: center;"] {
  text-align: left!important;
}
  .post-meta .author-date {
    color: $cca-grey;
    font-family: $arial-narrow;
    font-size: 16px;
    line-height: normal;

    time.entry-date.updated::before {
        content: "Published";
        padding-right: 5px;
    }

    i.icon-clock.mti_font_element {
      display: none;
    }
  }

  .title_wrapper {
    margin-bottom: 40px;
  }

  h3 {
    font-size: 22px;
    line-height: 120%;
  }

  .wp-caption {
      border-color: transparent;
  }

  p.wp-caption-text.mti_font_element {
    height: 100%;
    margin: 0;
    padding: 5%;
    text-align: left;
  }

  .blockquote,
  blockquote {
    color: $cca-dark-blue;
    font-family: $arial-bold-italic;
    font-size: 20px;
    line-height: 120%;
		h5 {
			color: $cca-dark-blue;
		}
  }

  .blockquote p.author {
    color: $cca-dark-blue;
    font-family: $arial-bold;
    font-size: 16px;
    margin-bottom: 7px;
    margin-top: 0;
    text-transform: uppercase;
  }

  .blockquote p.author i {
    display: none;
  }

}

//Subscribe Form
.subscribe-form {

  h3 {
    color: $cerulean;
    font-size: 16px;
    text-transform: uppercase;
  }

  form label {
    font-weight: 400;
  }

  form input[type="submit"] {
    width: 100%;
    border-color: $orange;
    color: $orange !important;

    &:hover {
      background-color: $orange !important;
    }
  }

}

//Post nav arrows
.fixed-nav .arrow {
  background-color: $denim;
}

.facets {

  h3 {
    color: $cerulean;
    font-size: 16px;
  }

  .facetwp-checkbox {
    color: $dark-grey;
    font-family: $arial-narrow;
    font-size: 18px;
    line-height: normal;
    margin-bottom: 12px;

    &:hover {
      color: $black;
    }
  }

  .facetwp-checkbox.checked {
    color: $orange;
  }

  span.facetwp-counter {
    display: none;
  }

}

ul.cca-blog li::before {
  content: none;
}

ul.cca-blog {
  padding-top: 0;
}

ul.cca-blog {

  a {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 24px;

    &:hover,
    &:hover .each-post__content {
      color: $black;
    }
  }

  .each-post {
    border-bottom: 1px solid $grey-box-light;
    margin-bottom: 24px;

    &__image {
      flex: 1 0 15%;
      margin: 0 10px 10px 0;
      overflow: hidden;
    }

    &__content {
      color: $dark-grey;
      flex: 1 0 80%;

      h2 {
        color: $orange;
        font-size: 24px;
        line-height: normal;
        margin-bottom: 5px;
      }

    }

    &__date {
      color: $cca-grey;
    }

  }

}

.page-id-7157, .cca-blog {
	h1, .facets h3, .subscribe-form h3 {
		color: $cca-dark-blue;
	}
}
